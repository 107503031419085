import React, { useState, useEffect } from "react";
import { Container, Table, Form, Button, Checkbox } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import BodyText from "../../components/body-text/body-text";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import CookieUtils from '../../utils/cookie-utils';

import "./index.css";

const Page = () => {
  const [analyticsConsentState, setAnalyticsConsentState] = useState(CookieUtils.analyticsConsentGiven());

  useEffect(() => {
    setAnalyticsConsentState(CookieUtils.analyticsConsentGiven());
  }, []);

  const toggleAnalyticsConsent = () => {
    const oldState = analyticsConsentState;
    setAnalyticsConsentState(!oldState);
  }

  const savePreferences = () => {
    CookieUtils.saveAnalyticsPreferences(analyticsConsentState);
    window.location.reload();
  }

  return (
    <LayoutInner>
      <SEO
          title="Website Cookie Policy"
          keywords={[`rocksteady`]}
          description="View our Cookie Policy and update your Cookie preferences."
      />
      <SecondaryNavBar
        product="generic"
        title="Cookie Policy"
        titlePath="/cookie-policy/"
        titleActive={true}
        links={[]}
      />
      <Container>
        <BodyText>
          <h2>Cookies</h2>
          <h3>What is a cookie?</h3>
          <p>
            A cookie is a small file that can be placed on your device that
            allows companies to recognise and remember you. When you visit our site,
            the 3rd party services we use may place analytics cookies on your device.
            This helps them collect data about their service and helps them display
            relevant content.
          </p>

          <h3>Your preferences</h3>
          <Form>
            <Form.Field
              control={Checkbox}
              checked={analyticsConsentState}
              label="Enable optional analytics cookies"
              onChange={toggleAnalyticsConsent}
            />
            <Button type='submit' primary onClick={savePreferences}>Update Preferences</Button>
          </Form>

          <h3>What type of cookies do we use?</h3>
            <Table striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Cookie</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Purpose</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Analytics Consent</Table.Cell>
                  <Table.Cell>analytics_consent</Table.Cell>
                  <Table.Cell>Essential</Table.Cell>
                  <Table.Cell>
                    This cookie is used to remember your choice about
                    analytics cookies on your device. If you have previously
                    indicated a preference, it will be stored in this cookie.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Vimeo</Table.Cell>
                  <Table.Cell>
                    __qca,
                    __ssid,
                    _abexps,
                    _delighted_web,
                    _fbp,
                    _ga,
                    _gcl_au,
                    _mkto_trk,
                    _uetvid,
                    afUserId,
                    auth_redirect,
                    continuous_play_v3,
                    has_logged_in,
                    is_logged_in,
                    sd_client_id,
                    vimeo,
                    vimeo_gdpr_optin,
                    player,
                    vuid
                  </Table.Cell>
                  <Table.Cell>Optional</Table.Cell>
                  <Table.Cell>
                    We embed videos from our official Vimeo channel. When
                    you press play Vimeo will drop third party cookies
                    to enable the video to play and to collect analytics
                    data such as how long a viewer has watched the video.
                    These cookies do not track individuals.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Google Analytics</Table.Cell>
                  <Table.Cell>
                  _ga, _ga_&lt;container-id&gt;
                  </Table.Cell>
                  <Table.Cell>Optional</Table.Cell>
                  <Table.Cell>
                    We use Google Analytics to monitor how people use our websites and digital systems, for example to see which pages are most popular.
                    Google Analytics uses cookies to understand when users return to our sites. 
                    We use Google Analytics to track anonymous behaviour of users, and not to track individuals.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Meta</Table.Cell>
                  <Table.Cell>
                    fr, wd, c_user, xs, presence, usida, sb, datr
                  </Table.Cell>
                  <Table.Cell>Optional</Table.Cell>
                  <Table.Cell>
                    We use the Meta Pixel to track website activity and run adverts based on that activity. 
                    For example, the Meta Pixel uses cookies to track when a user returns to our website.
                    We only enable these cookies if you have opted-in to Analytics Consent. 
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Twitter</Table.Cell>
                  <Table.Cell>
                    d_prefs, muc, muc_ads, personalization_id, guest_id_ads, guest_id_marketing, guest_id, auth_token, ga
                  </Table.Cell>
                  <Table.Cell>Optional</Table.Cell>
                  <Table.Cell>
                    We use the Twitter Pixel to track website activity and run adverts based on that activity.
                    For example, the Twitter Pixel uses cookies to track when a user returns to our website.
                    We only enable these cookies if you have opted-in to Analytics Consent.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>TikTok</Table.Cell>
                  <Table.Cell>
                    tta_attr_id_mirror, msToken, _abck, _ttp, tta_attr_id, ttwid, tt_csrf_token, tt_chain_token, cookie-consent
                  </Table.Cell>
                  <Table.Cell>Optional</Table.Cell>
                  <Table.Cell>
                    We use the TikTok Pixel to track website activity and run adverts based on that activity.
                    For example, the TikTok Pixel uses cookies to track when a user returns to our website.
                    We only enable these cookies if you have opted-in to Analytics Consent.
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>LinkedIn</Table.Cell>
                  <Table.Cell>
                    bcookie, bscookie, JSESSIONID, lang, lidc, sdsc, li_gc, li_mc, UID, UserMatchHistory,
                    AnalyticsSyncHistory, lms_ads, lms_analytics, li_fat_id, li_sugr, _guid, BizographicsOptOut,
                    li_giant
                  </Table.Cell>
                  <Table.Cell>Optional</Table.Cell>
                  <Table.Cell>
                    We use the LinkedIn Insight Tag to track website activity and run adverts based on that activity.
                    For example, the LinkedIn Insight Tag uses cookies to track when a user returns to our website.
                    We only enable these cookies if you have opted-in to Analytics Consent.
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
        </BodyText>
      </Container>
    </LayoutInner>
  );
}

export default Page;
